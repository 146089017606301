<template>
    <div class='CreateAndEdit'>
        <el-card class="box-card">
            <div>
                <i class="el-icon-close cha" @click="$router.go(-1)"></i>
                <div class="clearfix">
                    {{ id ? '修改' : '新增' }}备品备件分类
                </div>
            </div>

            <el-divider></el-divider>
            <div class="formbox">
                <el-form :model="spareClassInfo" :rules="rules" ref="spareClassInfo">
                    <!-- <el-form-item label="编号" :label-width="formLabelWidth" prop="id" v-if="state">
                        <el-input v-model="spareClassInfo.id" autocomplete="off" :readonly="state"></el-input>
                    </el-form-item> -->
                    <el-form-item label="级别" :label-width="formLabelWidth" prop="level">
                        <el-radio :disabled="state" v-model="spareClassInfo.level" :label=0>一级</el-radio>
                        <el-radio :disabled="state" v-model="spareClassInfo.level" :label=1>二级</el-radio>
                    </el-form-item>
                    <el-form-item label="分类编号" :label-width="formLabelWidth" prop="subClassCode" v-if="state">
                        <el-input v-model="spareClassInfo.subClassCode" autocomplete="off" :disabled="state"></el-input>
                    </el-form-item>
                    <el-form-item label="分类名称" :label-width="formLabelWidth" prop="subClassDesc">
                        <el-input v-model="spareClassInfo.subClassDesc" autocomplete="off" placeholder="请输入分类名称"></el-input>
                    </el-form-item>
                    <el-form-item label="上级分类" :label-width="formLabelWidth" v-if="!(spareClassInfo.level === 0)">
                        <!-- <el-input v-model="formInline.account" placeholder="请输入账号查询"></el-input> -->
                        <el-select placeholder="请选择上级分类名称" v-model="spareClassInfo.superClassCode">
                            <el-option v-for="(item, index) in oneClassList" :key="index" :label="item.subClassDesc"
                                :value="item.subClassCode"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item class="df" :label-width="formLabelWidth">
                        <el-button class="btn" :disabled="submitFlag" @click="submitForm()">提交</el-button>
                        <el-button @click="handleReset">重置</el-button>
                    </el-form-item>
                </el-form>

            </div>

        </el-card>
    </div>
</template>
<script>
import { querySpareOneClass, querySpareClass, addSpareClass, updateSpareClass } from '@/api/spareclass.js'
export default {
    props: {
        id: {
            type: [String, Number]
        }
    },
    data() {
        return {
            oneClassList: [],
            spareClassInfo: {
                id: '',
                subClassCode: '',
                subClassDesc: '',
                superClassCode: '',
                level: 0
            },
            formLabelWidth: '80px',
            rules: {
                level: [{ required: true }],
                subClassDesc: [{ required: true, message: '请输入分类名称' }],
                superClassCode: [{ required: true, message: '请选择上级分类' }]
            },
            submitFlag: false,// 是否提交中,
            state: this.id ? true : false

        }
    },
    mounted() {
        this.id && this.loadspareClassInfo()
        this.loadSelect()
    },
    methods: {
        async loadSelect() {
            await querySpareOneClass().then(res => {
                if (res.code === '000000') {
                    this.oneClassList = res.t
                }
            })
        },
        loadspareClassInfo() {
            querySpareClass(this.id).then(res => {
                if (res.code === '000000') {
                    this.spareClassInfo = res.t
                }
            })
        },
        submitForm() {
            this.$refs.spareClassInfo.validate((valid) => {
                if (valid) {
                    this.submitFlag = true
                    if (this.id) {
                        updateSpareClass(this.spareClassInfo).then(res => {
                            if (res.code === '000000') {
                                this.$message.success('修改成功')
                                setTimeout(() => {
                                    this.$router.go(-1)
                                }, 1000)
                            }
                            this.submitFlag = false
                        })
                    } else {
                        if(this.spareClassInfo.level===0){
                            this.spareClassInfo.superClassCode=''
                        }
                        addSpareClass(this.spareClassInfo).then(res => {
                            if (res.code === '000000') {
                                this.$message.success('添加成功')
                                this.$refs.spareClassInfo.resetFields()
                                setTimeout(() => {
                                    this.$router.go(-1)
                                }, 1000)
                            }
                            this.submitFlag = false
                        })
                    }
                } else {
                    return false
                }
            })
        },
        handleReset() {
            this.spareClassInfo.subClassDesc=''
            this.spareClassInfo.superClassCode=''
        }
    }
}

</script>

<style lang="scss" scoped>
.CreateAndEdit {
    .el-card {
        position: relative;
        text-align: left;

        .cha {
            position: absolute;
            top: 5px;
            right: 5px;
            font-size: 30px;
            color: rgba(54, 55, 56, 0.445);
        }

        .cha:hover {
            cursor: pointer;
            color: #2a2d2f;
        }
    }

    .el-form-item {
        width: 500px;
    }

    .df {
        text-align: left;
    }

}
</style>